import React, { useRef } from "react";
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    gql,
    useQuery,
} from "@apollo/client";
import * as THREE from "three";
import { useState, useEffect } from "react";
import Globe from "react-globe.gl";
import GothamPro from "./fonts/Gotham Pro_Regular.json";

const client = new ApolloClient({
    uri: "https://letoyakutia.ru/graphql",
    cache: new InMemoryCache(),
});

export default function World() {
    return (
        <ApolloProvider client={client}>
            <GlobeElement />
        </ApolloProvider>
    );
}

const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

function GlobeElement() {
    const prefix = isDev ? "" : "/wp-content/themes/betheme/build";
    const globeEl = useRef();

    const { data, loading, error } = useQuery(QUERY_GQL);
    const [geoData1, setGeoData1] = useState([]);
    const [geoData2, setGeoData2] = useState([]);

    useEffect(() => {
        window.globe = globeEl.current;
        const globe = globeEl.current;

        // Разрешаем зум и панорамирование
        const controls = globe.controls();
        controls.enableZoom = true;
        controls.enabled = true;

        // Ограничения на зум
        controls.maxDistance = 500; // максимальное отдаление
        controls.minDistance = 200; // минимальное приближение

        const loadAndSetData = async () => {
            try {
                const data1 = await loadGeoJSON(
                    prefix + "/globe/ne_110m_admin_0_countries.geojson"
                );
                setGeoData1(data1.features);
                console.log(data1);

                const data2 = await loadGeoJSON(
                    prefix + "/globe/combined.geojson"
                );
                setGeoData2(data2.features);
            } catch (error) {
                console.error("Error loading GeoJSON data:", error);
            }
        };
        loadAndSetData();
    }, []);

    useEffect(() => {
        if (data?.interactiveMap) {
            let map = data?.interactiveMap;

            window.globe.pointOfView(map?.camera?.startPoint, 0);
            window.globe.pointOfView(
                map?.camera?.endPoint,
                map?.camera?.duration
            );

            setTimeout(() => {
                const controls = window.globe.controls();
                controls.maxDistance = 340; // максимальное отдаление

                // Ограничения на панорамирование
                controls.addEventListener("change", (e) => {
                    if (window.closeModal) {
                        window.closeModal();
                    }
                    const { lat, lng, altitude } = window.globe.pointOfView();
                    const maxLat = 60; // Максимальное северное направление
                    const minLat = 30; // Максимальное южное направление
                    const maxLng = 140; // Максимальное восточное направление
                    const minLng = 70; // Максимальное западное направление

                    // Ограничиваем широту и долготу
                    const clampedLat = Math.max(Math.min(lat, maxLat), minLat);
                    const clampedLng = Math.max(Math.min(lng, maxLng), minLng);

                    // Применяем ограничения
                    if (lat !== clampedLat || lng !== clampedLng) {
                        window.globe.pointOfView({
                            lat: clampedLat,
                            lng: clampedLng,
                            altitude,
                        });
                    }
                });
                controls.domElement.onwheel = (e) => {
                    const altitude = window.globe.pointOfView().altitude;
                    if ((e.deltaY > 0 && altitude >= 2.4 - 0.01) || (e.deltaY < 0 && altitude <= 1 + 0.01))  {
                        window.scrollBy({
                            top: e.deltaY,
                            left: 0,
                            behavior: "instant",
                        });
                    }
                };
            }, map?.duration);

            // Фильтрация portfolios на основе совпадения location и name

            const points = map?.locations?.nodes?.map((locationItem) => ({
                ...locationItem,
                label: locationItem.showLabel ? locationItem.name : "",
                projects: data.portfolios.nodes.filter(
                    (portfolio) =>
                        portfolio.interactiveMapLocation.location ===
                        locationItem.name
                ),
            }));

            setMapPoints(points);
        }
    }, [loading]);

    const globeMaterial = new THREE.MeshBasicMaterial({ color: 0xebebeb });

    const [mapPoints, setMapPoints] = useState([]);

    const labelsTopOrientation = new Set([]);

    const combinedData = [
        ...geoData1.map((feature) => ({
            ...feature,
            properties: { ...feature.properties, dataset: "dataset1" },
        })),
        ...geoData2.map((feature) => ({
            ...feature,
            properties: { ...feature.properties, dataset: "dataset2" },
        })),
    ];

    const selectOption = (d, options) =>
        d.properties.dataset === "dataset1"
            ? d.properties.ADMIN === "Russia"
                ? options?.russia
                : options?.world
            : options?.yakutia;

    return (
        <ApolloProvider client={client}>
            <div className="relative h-[803px] overflow-hidden">
                <div className="absolute left-[50%] translate-x-[-50%] group-animate:">
                    <Globe
                        onZoom={(d) => console.log()}
                        width={1920}
                        height={1500}
                        backgroundColor="#00000000"
                        ref={globeEl}
                        globeMaterial={globeMaterial}
                        polygonsData={combinedData}
                        polygonCapColor={(d) =>
                            selectOption(
                                d,
                                data?.interactiveMap?.camera?.polygonCapColor
                            )
                        }
                        polygonSideColor={(d) =>
                            selectOption(
                                d,
                                data?.interactiveMap?.camera?.polygonSideColor
                            )
                        }
                        polygonStrokeColor={(d) =>
                            selectOption(
                                d,
                                data?.interactiveMap?.camera?.polygonStrokeColor
                            )
                        }
                        polygonAltitude={(d) =>
                            selectOption(
                                d,
                                data?.interactiveMap?.camera?.polygonAltitude
                            )
                        }
                        polygonsTransitionDuration={0}
                        animateIn={false}
                        showGraticules={true}
                        labelsData={mapPoints}
                        labelText="label"
                        labelSize={0.6}
                        labelAltitude={0.014}
                        labelDotRadius={0.4}
                        labelDotOrientation={(d) => "bottom"}
                        labelColor={(d) => "#FF7161"}
                        onLabelClick={(d) => {
                            console.log(d);
                            if (window.openMapModal) {
                                window.openMapModal(d);
                            }
                        }}
                        labelTypeFace={GothamPro}
                        labelResolution={2}
                    />
                </div>
            </div>
        </ApolloProvider>
    );
}

function loadGeoJSON(url) {
    return fetch(url).then((res) => {
        if (!res.ok) {
            throw new Error("Network response was not ok");
        }
        return res.json();
    });
}

const QUERY_GQL = gql`
    query NewQuery {
        interactiveMap {
            camera {
                startPoint {
                    lat
                    lng
                    altitude
                }
                endPoint {
                    lat
                    lng
                    altitude
                }
                duration
                polygonSideColor {
                    world
                    russia
                    yakutia
                }
                polygonStrokeColor {
                    world
                    russia
                    yakutia
                }
                polygonCapColor {
                    world
                    russia
                    yakutia
                }
                polygonAltitude {
                    world
                    russia
                    yakutia
                }
            }
            locations {
                nodes {
                    lat
                    lng
                    showLabel
                    name
                }
            }
        }
        portfolios {
            nodes {
                title
                interactiveMapLocation {
                    location
                    mestopolojenie
                    tipologiya
                    summaGranta
                }
                link
            }
        }
    }
`;
