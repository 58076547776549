import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import App from "./app";

import { register } from 'swiper/element/bundle';
// register Swiper custom elements
register();

const consoleWarn = console.error;
const SUPPRESSED_WARNINGS = ['Warning: Invalid DOM property'];

console.error = function filterWarnings(msg, ...args) {
    if (!SUPPRESSED_WARNINGS.some((entry) => msg.includes(entry))) {
        consoleWarn(msg, ...args);
    }
};


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
